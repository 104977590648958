import Logo from "_components/_layout/Logo";
import Navigation from "../Navigation";
import SocialIcons from "../SocialIcons";
import styles from "./styles.module.scss";

const secondaryLogo = {
  sourceUrl: "/static/images/logo-white.svg",
  altText: "SanSon LLC Construction and Renovation",
};

const Footer = () => {
  return (
    <>
      <div className={styles.bar} />
      <div className={styles.footerContainer}>
        <div className={styles.logoContainer}>
          <Logo imgData={secondaryLogo} />
        </div>
        <Navigation appearance="footer" />
        <SocialIcons />
      </div>
    </>
  );
};

export default Footer;
