import { useRouter } from "next/router";
import Header from "_components/_layout/Header";
// import { gql, useQuery } from "@apollo/client";
import Footer from "_components/_layout/Footer";

const Layout = ({ children }) => {
  const router = useRouter();

  if (router.pathname === "/houston-roofing") {
    return (
      <div>
        <Header menu={false}></Header>
        {children}
        <Footer />
      </div>
    );
  }

  if (router.pathname === "/houston-roofing/installations") {
    return <div>{children}</div>;
  }

  if (router.pathname === "/houston-roofing/repairs") {
    return <div>{children}</div>;
  }

  if (router.pathname === "/houston-roofing/inspections") {
    return <div>{children}</div>;
  }

  return (
    <div>
      <Header menu={true}></Header>
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

export async function getStaticProps() {
  const data = await getContent();
  return {
    props: {
      data,
    },
  };
}
