import React from "react";
import Image from "next/image";
import styles from "./styles.module.scss";

interface LogoProps {
  imgData?: { sourceUrl: string; altText: string };
}

const Logo = ({ imgData }: LogoProps) => {
  return (
    <Image
      src={imgData.sourceUrl}
      height="45px"
      width="176.9418px"
      objectFit="fill"
      alt={imgData.altText}
    />
  );
};

export default Logo;
