import React, { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Logo from "_components/_layout/Logo";
import Link from "next/link";
import HamburgerButton from "_components/_layout/HamburgerButton";
import Sidebar from "_components/_layout/Sidebar";

const primaryLogo = {
  sourceUrl: "/static/images/logo.svg",
  altText: "SanSon LLC Construction and Renovation",
};

const Header = ({ menu, className, _routes }) => {
  const [toggle, setToggle] = useState(false);

  const showMenu = menu ? (
    <HamburgerButton
      toggle={toggle}
      onClick={() => {
        setToggle(!toggle);
      }}
    />
  ) : (
    <p className={styles.emphasis}>
      Serving the Greater Houston area
      <br className="visible-md" /> for more than 10 years
    </p>
  );

  return (
    <>
      <div className={classNames(className, styles.header)}>
        <Sidebar routes={_routes} toggle={toggle} setToggle={setToggle} />
        <div className={styles.headerContent}>
          <Link href="/">
            <a
              className={classNames(styles.logoLink, {
                [styles.noFlexEnd]: menu,
              })}
            >
              <Logo imgData={primaryLogo} />
            </a>
          </Link>
          {showMenu}
        </div>
      </div>
    </>
  );
};

export default Header;
