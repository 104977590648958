import Link from "next/link";
import styles from "./styles.module.scss";
import classNames from "classnames";

export interface NavigationTypes {
  link?: string;
  appearance?: string;
}

const links = [
  {
    name: "Home",
    url: "#",
  },
  {
    name: "About",
    url: "/#about",
  },
  {
    name: "Services",
    url: "/#services",
  },
  {
    name: "Contact Us",
    url: "/#contact",
  },
];

const Navigation = ({ appearance }: NavigationTypes) => {
  return (
    <div
      className={classNames(
        styles.navigationContainer,
        classNames({
          [styles.footer]: appearance === "footer",
        })
      )}
    >
      <ul
        className={classNames({
          [styles.footerUl]: appearance === "footer",
        })}
      >
        {links.map((link, key) => {
          return (
            <li key={key}>
              <Link href={link.url}>
                <a
                  className={classNames({
                    [styles.footerLink]: appearance === "footer",
                  })}
                >
                  {link.name}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Navigation;
