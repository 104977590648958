import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient.ts";
import Layout from "_components/_layout/Layout";
import "../styles/globals.scss";

export default function MyApp({ Component, pageProps }) {
  return (
    <ApolloProvider client={useApollo()}>
      <Head>
        <meta
          name="description"
          content="Sanson is your Houston area trustworth roofing and construction company."
        />
        <link rel="preconnect" href="https://sansonllc.132clients.com" />
        <title>Sanson LLC Contruction &amp; Roofing</title>
        <link rel="icon" href="/static/images/favicon.svg" />
        <link rel="stylesheet" href="https://use.typekit.net/nvp4mfh.css" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ApolloProvider>
  );
}
