import React, { useEffect } from "react";
import Navigation from "../Navigation";
import classNames from "classnames";
import styles from "./styles.module.scss";

const Sidebar = ({ toggle, setToggle }) => {
  useEffect(
    () => {
      const domClick = (e) => {
        if (
          e.target.id !== "main-sidemenu" &&
          !e.target.closest("#hamburger-button")
        ) {
          setToggle(false);
        }
      };

      document.addEventListener("click", domClick); // adds eventListener that contains elements properties
      return () => document.removeEventListener("click", domClick); // removes eventListener
    },
    [setToggle] //useEffect dependency to reinitiate itself.
  );

  return (
    <div
      id="main-sidemenu"
      className={classNames(styles.sidebar, {
        [styles.slideIn]: toggle,
      })}
    >
        <Navigation appearance="navigation" />
    </div>
  );
};

export default Sidebar;
