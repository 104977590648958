import Link from "next/link";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faEnvelope,
  faPhoneSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const twitterIcon = <FontAwesomeIcon icon={faTwitter} />;
const instagramIcon = <FontAwesomeIcon icon={faInstagram} />;
const facebookIcon = <FontAwesomeIcon icon={faFacebook} />;
const googleMapsIcon = <FontAwesomeIcon icon={faMapMarkedAlt} />;
const phoneIcon = <FontAwesomeIcon icon={faPhoneSquareAlt} />;
const emailIcon = <FontAwesomeIcon icon={faEnvelope} />;
const youtubeIcon = <FontAwesomeIcon icon={faYoutube} />;

export interface SocialIconsTypes {
  link: string;
}

const getIcon = (network) => {
  switch (network) {
    case "Twitter":
      return twitterIcon;
    case "Instagram":
      return instagramIcon;
    case "Facebook":
      return facebookIcon;
    case "Google Maps":
      return googleMapsIcon;
    case "Phone":
      return phoneIcon;
    case "Email":
      return emailIcon;
    case "Youtube":
      return youtubeIcon;
  }
};

const currentNetworks = "Instagram";

const socialNetworks = [
  {
    socialMediaNetwork: "Instagram",
    socialMediaLink:
      "https://www.instagram.com/sanson_construction_renovation/",
  },
];

const SocialIcons = () => {
  return (
    <div className={styles.socialIconsContainer}>
      <ul>
        {socialNetworks.map((socialNetwork, key) => {
          const icon = getIcon(currentNetworks);
          return (
            <li key={key}>
              <Link href={`${socialNetwork.socialMediaLink}`}>
                <a aria-label={socialNetwork.socialMediaNetwork}>{icon}</a>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SocialIcons;
